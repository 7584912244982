<script>
export default {
  name: 'ChangePassword',
  created() {
    this.receiveDetails();
  },
  methods: {
    receiveDetails() {
      localStorage.setItem('passToken', this.$route.params.token);
      this.$router.push({ path: '/changePassword' });
    },
  },
};
</script>
